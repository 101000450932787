import React from "react";
import { useSelector } from "react-redux";
import CardInSellCart from "./CardInSellCart";

const StepOneSellCards = () => {
  const cardIds = useSelector(state => state.cartSell.cardIds);
  const cards = useSelector(state => state.cards);

  return (
    <>
      {cardIds.map((card, index) => {
        if (!cards[card]) {
          return null;
        }
        return <CardInSellCart key={index} index={index} cardID={card} />;
      })}
    </>
  );
};

export default StepOneSellCards;
