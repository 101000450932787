import React, { useState, useEffect } from "react";
import { VariableSizeList as List } from "react-window";

const SellCardMenu = props => {
  const [theList, changeTheList] = useState(
    React.Children.toArray(props.children)
  );
  useEffect(() => {
    changeTheList(React.Children.toArray(props.children));
  }, [props.children]);
  const GROUP_HEADER_HEIGHT = 13;
  const ITEM_HEIGHT = 60;
  const { options, getValue } = props;
  const [value] = getValue();

  const initialOffset = options.indexOf(value) * ITEM_HEIGHT;

  function getOptionSize(option) {
    if (option?.options) {
      return option.options.length * ITEM_HEIGHT + GROUP_HEADER_HEIGHT;
    }
    return ITEM_HEIGHT;
  }

  function getItemSize(i) {
    return getOptionSize(options[i]);
  }

  const totalHeight = Array.isArray(props.children)
    ? props.children.reduce((height, option) => {
        return height + ITEM_HEIGHT;
      }, 0)
    : ITEM_HEIGHT;

  const estimatedItemSize = totalHeight / props.children.length;
  return (
    <List
      height={Math.min(totalHeight, 300)}
      itemCount={theList.length}
      itemSize={getItemSize}
      estimatedItemSize={estimatedItemSize}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{theList[index]}</div>}
    </List>
  );
};

export default SellCardMenu;
