import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import DefaultCustomButton from "@modules/Components/DefaultCustomButton";
import { useDispatch, useSelector } from "react-redux";
import { addSellCard } from "@actions/sellActions";
import { defaultAction } from "@actions/defaultAction";
import { SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID } from "@constants/actionTypes";
import { currencyFormatter } from "@utils";
import EachOffer from "./EachOffer";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { loadLinks } from "@helpers/loadScripts";
// import TradeOptionTooltip from "./TradeOptionTooltip";

const OfferContainer = styled.div`
  margin-top: 65px;

  @media (max-width: 710px) {
    margin-top: 54px;
  }
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-gap: 24px;

  @media (max-width: 710px) {
    grid-template-columns: auto;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchSpinner = styled.img`
  width: 20px;
  height: 20px;
`;

const TradeForGrid = styled.div`
  display: grid;
  max-width: 910px;
  margin: 0 auto;
  grid-template-columns: 1fr auto 1fr;
  margin-top: 40px;
  margin-bottom: 30px;
  align-items: center;
  grid-gap: 29px;

  @media (max-width: 760px) {
    max-width: 560px;
  }
`;

const GridLine = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.15;
`;

const GridLineLeft = styled(GridLine)`
  background-image: linear-gradient(to right, rgba(66, 67, 69, 0.1), #1f2021);
`;

const GridLineRight = styled(GridLine)`
  background-image: linear-gradient(to left, rgba(66, 67, 69, 0.1), #1f2021);
`;

const TradeText = styled.div`
  font-size: 16px;
  text-align: center;
  color: #1f2021;
`;

const TradeSpan = styled.span`
  font-weight: 500;
`;

const EachOfferGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;

  @media (max-width: 1550px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1320px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1000px;
    margin: 0 auto;
  }
  @media (max-width: 880px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 600px;
  }
  @media (max-width: 635px) {
    grid-template-columns: 1fr;
    max-width: 318px;
  }
`;

const TradeButtonGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  grid-gap: ${props => props.gridGap};
  align-items: center;

  @media (max-width: 390px) {
    font-size: 14px;
  }
  @media (max-width: 315px) {
    font-size: 13px;
  }
  @media (max-width: 290px) {
    font-size: 12px;
  }
`;

const MainOffer = styled.div`
  width: 100%;
  height: 66px;
  border-radius: 10px;
  box-shadow: 0 9px 18px 0 rgba(31, 32, 33, 0.08);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 61px 1fr;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 9px 18px 0 rgba(31, 32, 33, 0.12);
  }
`;

const Image = styled.img`
  width: 61px;
  height: 42px;
  border-radius: 2px;
`;

const Info = styled.div`
  margin-left: 13px;
  font-size: 14px;

  @media (max-width: 950px) {
    font-size: 13px;
  }
  @media (max-width: 310px) {
    font-size: 12px;
  }
`;

const Name = styled.div`
  font-weight: 500;
  line-height: 2.14;
  color: #1f2021;
`;

const Offer = styled.div`
  color: #777984;
  justify-self: flex-end;
`;

const ArrowContainer = styled.div`
  position: absolute;
  right: 20px;
`;

const Arrow = styled(IoIosArrowForward)`
  color: rgba(119, 121, 132, 0.48);
  font-size: 16px;
`;

const Dot = styled.div`
  background-color: #75aeff;
  border-radius: 50%;
  height: 5px;
  width: 5px;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  font-family: "Gochi Hand";
  top: -16px;
  right: -145px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  align-items: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const AbsoluteText = styled.div`
  font-size: 18px;
  color: #1f2021;
  top: -21px;
  right: -149px;
`;

const SaveArrow = styled.img`
  width: 48px;
  position: absolute;
  top: 31px;
  left: 12px;
`;

const PriorityContainer = styled.div`
  position: relative;
`;

const StepOneOfferContainer = ({ highestPerecentageOff }) => {
  const dispatch = useDispatch();
  const sellCardOfferLoader = useSelector(
    state => state.cartSell.sellCardOfferLoader
  );
  const cardPartial = useSelector(state => state.cardPartial);
  const cards = useSelector(state => state.cards);
  const tradeOptions = useSelector(state => state.offers.trade);

  const trade = tradeOptions?.filter(
    tradeOption =>
      tradeOption.merchantId !==
      Object.values(cards)?.find(
        sellCartCard => sellCartCard?.id === tradeOption?.merchantId
      )?.id
  );

  const tradeRef = useRef();

  useEffect(() => {
    loadLinks(
      "https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap",
      "gochiFont"
    );
  }, []);

  const addCardAndShowHowWouldYouLikeToGetPaid = () => {
    if (cardPartial.selectedMerchant && cardPartial.cardBalance) {
      dispatch(addSellCard());
    }
    dispatch(defaultAction(SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID, "/step-two/"));
  };

  let total = 0;
  if (Object.keys(cards).length !== 0) {
    for (var key in cards) {
      total += Number(cards[key].cashValue);
    }
  }

  const getOfferTotal = offer => {
    let offerTotal = 0;
    if (Object.keys(cards).length !== 0) {
      for (let key in cards) {
        offerTotal +=
          parseFloat((offer.discount / 100) * Number(cards[key].cashValue)) +
          parseFloat(Number(cards[key].cashValue));
      }
    }
    return offerTotal;
  };

  return (
    <OfferContainer className={"trade-offer-container"}>
      <ButtonContainer>
        <DefaultCustomButton
          handleClick={() => {
            !sellCardOfferLoader && addCardAndShowHowWouldYouLikeToGetPaid();
          }}
          classes={"main-default-orange-button"}
          type="button"
          buttonType={"GREEN_BUTTON"}
        >
          <span>
            {sellCardOfferLoader ? (
              <SpinnerContainer>
                <SearchSpinner
                  className="spinner-rotate"
                  src={
                    "https://cdn.cardcash.com/website/ui/elements/spinner-white.png"
                  }
                  alt=""
                />
              </SpinnerContainer>
            ) : (
              <span>{"Get " + currencyFormatter(total) + " Cash"}</span>
            )}
          </span>
        </DefaultCustomButton>
        {trade?.length > 0 &&
        trade[0].tradePriority > 0 &&
        getOfferTotal(trade[0]) > trade[0].limit.minPrice ? (
          <PriorityContainer>
            <AbsoluteBox>
              <AbsoluteText>Get even more</AbsoluteText>
              {/* <TradeOptionTooltip />  keeping here in case we want to use this again for a partner that needs terms and conditions here */}
              <SaveArrow
                src="https://cdn.cardcash.com/website/ui/sell/arrow.png"
                alt=""
              />
            </AbsoluteBox>
            <EachOffer offer={trade[0]}>
              <DefaultCustomButton
                handleClick={() => {}}
                classes={"main-default-orange-button"}
                type="button"
                buttonType={"BLUE_BUTTON"}
              >
                <TradeButtonGrid
                  gridTemplateColumns={"auto auto auto"}
                  gridGap={"5px"}
                >
                  <span>
                    Trade for {trade[0].name.replace("eGift Card", "").trim()}
                  </span>
                  <Dot />
                  <span>{currencyFormatter(getOfferTotal(trade[0]))}</span>
                </TradeButtonGrid>
              </DefaultCustomButton>
            </EachOffer>
          </PriorityContainer>
        ) : (
          <DefaultCustomButton
            handleClick={() => {
              window.scrollTo({
                top: tradeRef.current.offsetTop,
                behavior: "smooth"
              });
            }}
            classes={"main-default-orange-button"}
            type="button"
            buttonType={"WHITE_BUTTON_GREEN_COLOR"}
          >
            <TradeButtonGrid gridTemplateColumns={"auto 1fr"} gridGap={"10px"}>
              <IoIosArrowDown
                style={{
                  color: "rgba(57, 200, 118, 0.32)",
                  fontSize: "21px"
                }}
              />
              Trade for more
            </TradeButtonGrid>
          </DefaultCustomButton>
        )}
      </ButtonContainer>
      <TradeForGrid>
        <GridLineLeft />
        <TradeText>
          or
          <TradeSpan>
            &nbsp;trade and get up to {highestPerecentageOff}%&nbsp;
          </TradeSpan>
          more
        </TradeText>
        <GridLineRight />
      </TradeForGrid>
      <EachOfferGrid ref={tradeRef}>
        {trade?.length > 0 &&
          trade
            .filter(offer => getOfferTotal(offer) > offer.limit.minPrice)
            .map((offer, index) => (
              <EachOffer key={index} offer={offer}>
                <MainOffer>
                  <Image src={offer.logo} alt="logo" />
                  <Info>
                    <Name>{offer.name.replace("eGift Card", "").trim()}</Name>
                    <Offer>{currencyFormatter(getOfferTotal(offer))}</Offer>
                  </Info>
                  <ArrowContainer>
                    <Arrow />
                  </ArrowContainer>
                </MainOffer>
              </EachOffer>
            ))}
      </EachOfferGrid>
    </OfferContainer>
  );
};

export default StepOneOfferContainer;
