import React, { useState } from "react";
import styled from "@emotion/styled";
import { components } from "react-select";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { defaultAction } from "@actions/defaultAction";
import {
  KEEP_ADD_CARD_BOX_CLOSED,
  ADD_CARD_BOX_OPEN
} from "@constants/actionTypes";

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconDiv = styled.div`
  position: absolute;
  left: 25px;

  @media (max-width: 875px) {
    left: 19px;
  }
  @media (max-width: 410px) {
    left: 13px;
  }
`;

const SellCardInputField = props => {
  const dispatch = useDispatch();

  const cards = useSelector(state => state.cards);
  const displayAddCardBox = useSelector(
    state => state.userActions.displayAddCardBox
  );
  const [selectOpened, setSelectOpened] = useState(false);

  const setFocusAction = () => {
    if (
      Object.keys(cards).length &&
      Object.keys(cards).length < 2 &&
      displayAddCardBox
    ) {
      dispatch(defaultAction(ADD_CARD_BOX_OPEN, false));
      dispatch(defaultAction(KEEP_ADD_CARD_BOX_CLOSED, true));
    }
  };

  const onFocus = e => {
    props.onFocus(e);
    setSelectOpened(true);
    setFocusAction();
  };

  const onBlur = e => {
    props.onBlur(e);
    setSelectOpened(false);
  };

  return (
    <InputContainer>
      <IconDiv>
        <FaSearch
          style={{
            color: props.selectProps.merchantError
              ? "#ee5050"
              : selectOpened
              ? "#1f2021"
              : "rgba(31, 32, 33, 0.3)"
          }}
        />
      </IconDiv>
      <components.Input {...props} onFocus={onFocus} onBlur={onBlur} />
    </InputContainer>
  );
};

export default SellCardInputField;
