import React, { useState } from "react";
import {
  SET_PAYOUT,
  HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID
} from "@constants/actionTypes";
import { defaultAction } from "@actions/defaultAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TradeCardAcceptTerms from "../modules/UI/Popups/TradeCardAcceptTerms";

const EachOffer = ({ offer, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cards = useSelector(state => state.cards);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const navToStep2 = () => {
    let preventGoToNextStep = false;
    for (let theCard in cards) {
      if (cards[theCard].balanceError !== "PASSED") {
        preventGoToNextStep = true;
      }
    }
    if (preventGoToNextStep) {
      return;
    }
    dispatch(defaultAction(HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID));
    history.push("/step-two/");
  };

  const offerClick = () => {
    if (offer?.terms && !showTermsModal) {
      return setShowTermsModal(true);
    }
    dispatch(
      defaultAction(SET_PAYOUT, {
        payoutType: offer.name,
        payoutMethod: "TRADEOPTION",
        payoutMethodTradeID: offer.id,
        tradeBonus: offer.discount
      })
    );

    navToStep2();
  };

  return (
    <>
      {showTermsModal && (
        <TradeCardAcceptTerms
          terms={offer.terms}
          onClose={() => setShowTermsModal(false)}
          onAccept={offerClick}
        />
      )}
      <div onClick={offerClick}>{children}</div>
    </>
  );
};

export default EachOffer;
