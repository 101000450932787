import React from "react";
import styled from "@emotion/styled";
import { components } from "react-select";

const OptionImage = styled.img`
  width: 40px;
  height: 29px;
  margin-right: 10px;
  margin-left: 10px;
`;

const InnerOption = styled.div`
  width: 95%;
  margin: 0 auto;
  border-bottom: 1px rgba(119, 121, 132, 0.15) solid;
  height: 100%;
  display: flex;
  align-items: center;
`;

const SellCardOptions = props => {
  props.innerProps.onMouseMove = f => f;
  props.innerProps.onMouseOver = f => f;
  return (
    <components.Option {...props}>
      <InnerOption>
        <OptionImage src={props.data.image} alt="payment option" />
        {props.children}
      </InnerOption>
    </components.Option>
  );
};

export default SellCardOptions;
