import React, { useRef } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import CardCashSpinner from "./CardCashSpinner";
import MailInStatusBar from "./MailInStatusBar";
import EachSellCard from "./EachSellCard";
import StepOneSellCards from "./StepOneSellCards";

const MainContent = styled.div`
  max-width: 904px;
  margin: 0 auto;
  box-shadow: 0 20px 80px 0 rgba(119, 121, 132, 0.16);
  background-color: #ffffff;
  margin-top: -64px;
  padding-bottom: 30px;
  min-height: 265px;

  @media (max-width: 1150px) {
    max-width: 804px;
  }
  @media (max-width: 875px) {
    max-width: 680px;
  }
  @media (max-width: 710px) {
    max-width: 500px;
  }
  @media (max-width: 600px) {
    position: relative;
  }
  @media (max-width: 550px) {
    max-width: 90%;
  }
`;

const Loader = styled.div`
  padding-top: 30px;
`;

const AllCardsContainer = styled.div`
  padding-top: 30px;
`;

const StepOneMain = () => {
  const mainContentRef = useRef(null);
  const initialSellCartLoading = useSelector(
    state => state.popupsActions.initialSellCartLoading
  );
  const cardIds = useSelector(state => state.cartSell.cardIds);
  const cards = useSelector(state => state.cards);

  return (
    <MainContent ref={mainContentRef}>
      {initialSellCartLoading ? (
        <Loader>
          <CardCashSpinner loading={"eager"} />
        </Loader>
      ) : (
        <>
          <MailInStatusBar backgroundColor={"rgba(119, 121, 132, 0.16)"} />
          <AllCardsContainer>
            {cardIds.length > 0 && Object.keys(cards).length > 0 && (
              <StepOneSellCards />
            )}
          </AllCardsContainer>
          <EachSellCard mainContentRef={mainContentRef} />
        </>
      )}
    </MainContent>
  );
};

export default StepOneMain;
