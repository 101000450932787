import React from "react";
import styled from "@emotion/styled";
import DefaultCustomButton from "@modules/Components/DefaultCustomButton";
import classNames from "classnames";
import { useEffect } from "react";

const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 109;
  background-color: rgba(31, 32, 33, 0.8);
  overflow: scroll;
`;

const Container = styled.div`
  width: 579px;
  height: 450px;
  border-radius: 30px;
  background-color: #f8f8f8;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  @media (max-height: 675px) {
    margin-top: 70px;
  }
  @media (max-height: 600px) {
    margin-top: 35px;
  }
  @media (max-height: 560px) {
    margin-top: 20px;
  }

  @media (max-width: 590px) {
    width: 100%;
    height: 550px;
  }
`;

const CloseDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #9b9b9b;
  align-self: flex-start;
  padding-top: 20px;
  padding-right: 26px;
  padding-left: 26px;

  @media (max-height: 600px) {
    padding-right: 20px;
    padding-top: 20px;
  }
`;

const CloseDivImage = styled.img`
  width: 17px;
`;

const CloseSpan = styled.span`
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${props => props.setVisibility};
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 430px;
  flex: 1;

  @media (max-width: 600px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
`;

const Header = styled.div`
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  color: #1f2021;
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 35px;

  @media (max-width: 400px) {
    font-size: 26px;
    max-width: 274px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 35px;

  @media (max-width: 590px) {
    flex-direction: column-reverse;
  }
`;

const handleResize = () => {
  if (window.innerHeight > 545 && window.innerWidth > 445) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "initial";
  }
};

const HowWouldYouLikeToGetPaid = ({ onAccept, onClose, terms }) => {
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.scrollTo(0, 0);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.innerHeight > 545 && window.innerWidth > 445) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "initial");
  }, []);

  return (
    <PopUpContainer
      onClick={onClose}
      className={classNames("animated", "fast", "fadeInUp")}
    >
      <Container onClick={e => e.stopPropagation()}>
        <CloseDiv className="mar-t-10">
          <CloseSpan onClick={onClose} setVisibility="visible">
            <CloseDivImage
              src="https://cdn.cardcash.com/website/ui/elements/close.svg"
              aria-hidden="true"
              alt="close button"
            />
          </CloseSpan>
        </CloseDiv>
        <Content>
          <Header>Please Note:</Header>
          <p>{terms}</p>
        </Content>
        <ButtonContainer>
          <DefaultCustomButton
            buttonType="WHITE_BUTTON_ORANGE_COLOR"
            handleClick={onClose}
          >
            Cancel
          </DefaultCustomButton>
          <DefaultCustomButton
            buttonType="ORANGE_BUTTON"
            handleClick={onAccept}
          >
            Continue
          </DefaultCustomButton>
        </ButtonContainer>
      </Container>
    </PopUpContainer>
  );
};

export default HowWouldYouLikeToGetPaid;
