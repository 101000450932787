import React from "react";
import styled from "@emotion/styled";
import { components } from "react-select";
import DeleteCardTrashCan from "@components/DeleteCardTrashCan";
import { useSelector } from "react-redux";

const ValueImage = styled.img`
  width: 56px;
  height: 36px;
`;

const ValueImageContainer = styled.div`
  position: relative;
`;

const InnerSingleValue = styled.div`
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const DeleteWrapper = styled.span`
  pointer-events: all;
`;

const ValueSpan = styled.div`
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const SellCardValueContainer = props => {
  const deleteCardRunning = useSelector(
    state => state.cartSell.deleteCardRunning
  );

  return (
    <components.SingleValue {...props}>
      <InnerSingleValue>
        <ValueSpan>{props.children}</ValueSpan>
        <ValueImageContainer>
          {props.selectProps.deleteSellCardInCart ? (
            <DeleteWrapper
              onMouseEnter={() => {
                props.selectProps.setDisableSelect(true);
              }}
              onMouseLeave={() => {
                props.selectProps.setDisableSelect(false);
              }}
            >
              <DeleteCardTrashCan
                setClass={"sell-page-delete-trash"}
                img={props.data.image}
                handleTouchEnd={() => {
                  if (!deleteCardRunning) {
                    props.selectProps.deleteSellCardInCart(props.data.cardID);
                  }
                  props.selectProps.setDisableSelect(false);
                }}
                handleTouchStart={() => {
                  props.selectProps.setDisableSelect(true);
                }}
                handleClick={() => {
                  if (!deleteCardRunning) {
                    props.selectProps.deleteSellCardInCart(props.data.cardID);
                  }
                }}
              />
            </DeleteWrapper>
          ) : (
            <ValueImage src={props.data.image} alt="" />
          )}
        </ValueImageContainer>
      </InnerSingleValue>
    </components.SingleValue>
  );
};

export default SellCardValueContainer;
