import React from "react";
import styled from "@emotion/styled";

const MessageContainer = styled.div`
  max-width: 910px;
  margin: 0 auto;
  margin-top: 96px;

  @media (max-width: 1015px) {
    max-width: 850px;
  }
`;

const MessageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 25px 1fr;
  grid-gap: 80px;

  @media (max-width: 1015px) {
    grid-gap: 40px;
  }
  @media (max-width: 710px) {
    grid-gap: 20px;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    max-width: 360px;
    margin: 0 auto;
  }
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 28px;

  @media (max-width: 890px) {
    text-align: center;
    display: block;
  }
  @media (max-width: 650px) {
    display: grid;
    text-align: left;
  }
  @media (max-width: 385px) {
    grid-gap: 20px;
  }
`;

const ImageItem = styled.div`
  border-radius: 0px 0px 0px 25px;
  background-color: rgba(57, 200, 118, 0.1);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 890px) {
    margin: 0 auto;
  }
`;

const MessageImage = styled.img`
  filter: drop-shadow(0px 10px 10px rgba(57, 200, 118, 0.3));
  width: 22px;
  height: ${props => props.setHeight};
`;

const TextMessage = styled.div`
  @media (max-width: 890px) {
    max-width: 286px;
    margin: 0 auto;
    margin-top: 30px;
  }
  @media (max-width: 650px) {
    margin: 0;
  }
`;

const TextHeader = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 2;
  color: #1f2021;
  margin-bottom: 7px;

  @media (max-width: 665px) {
    font-size: 17px;
  }
  @media (max-width: 385px) {
    font-size: 16px;
  }
`;

const TextP = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: #777984;
`;

const MiddleGridItem = styled.div`
  display: grid;
  justify-content: center;

  @media (max-width: 890px) {
    align-self: flex-end;
  }
  @media (max-width: 650px) {
    display: none;
  }
`;

const MiddleSpan = styled.span`
  @media (max-width: 890px) {
    min-height: 36px;
  }
  @media (max-width: 650px) {
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const Line = styled.div`
  width: 1px;
  height: 44px;
  opacity: 0.15;
  background-image: linear-gradient(
    to bottom,
    #1f2021,
    rgba(31, 32, 33, 0.2) 150%
  );
  justify-self: center;
`;

const MobileMiddleGridItem = styled.div`
  @media (min-width: 651px) {
    display: none;
  }
  display: flex;
  align-items: center;
`;
const VlineContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
const VLine = styled.div`
  opacity: 0.15;
  width: 100%;
  height: 1px;
`;

const VLineLeft = styled(VLine)`
  background-image: linear-gradient(
    to left,
    #1f2021 0%,
    rgba(31, 32, 33, 0.2) 100%
  );
`;

const VLineRight = styled(VLine)`
  background-image: linear-gradient(
    to right,
    #1f2021 0%,
    rgba(31, 32, 33, 0.2) 100%
  );
`;

const StepOneSellBottom = ({ highestPerecentageOff }) => {
  return (
    <MessageContainer>
      <MessageGrid>
        <GridItem>
          <ImageItem>
            <MessageImage
              src="https://cdn.cardcash.com/website/ui/sell/cash-bills.svg"
              loading="lazy"
              setHeight={"16px"}
              alt="cash image"
            />
          </ImageItem>
          <TextMessage>
            <TextHeader>Get cash</TextHeader>
            <TextP>
              You’ve got no use for your unwanted gift cards. Sell them and get
              paid via check, ACH or paypal. Now that’s something you can Bank
              On!
            </TextP>
          </TextMessage>
        </GridItem>
        <MiddleGridItem>
          <MiddleSpan>or</MiddleSpan>
          <Line />
        </MiddleGridItem>
        <MobileMiddleGridItem>
          <VlineContainer>
            <VLineLeft />
          </VlineContainer>
          <MiddleSpan>or</MiddleSpan>
          <VlineContainer>
            <VLineRight />
          </VlineContainer>
        </MobileMiddleGridItem>
        <GridItem>
          <ImageItem>
            <MessageImage
              src="https://cdn.cardcash.com/website/ui/sell/trade.svg"
              loading="lazy"
              setHeight={"20px"}
              alt="trade image"
            />
          </ImageItem>
          <TextMessage>
            <TextHeader>Trade and get gift cards</TextHeader>
            <TextP>
              Want even more bang for your buck? Trade in your unwanted gift
              card for a vendor you love and get paid up to&nbsp;
              {highestPerecentageOff}% more. It’s the gift that keeps on giving!
            </TextP>
          </TextMessage>
        </GridItem>
      </MessageGrid>
    </MessageContainer>
  );
};

export default StepOneSellBottom;
