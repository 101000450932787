import React, { useState } from "react";
import Select from "react-select";
import styled from "@emotion/styled";
import { score } from "@utils/fuzzySearch";
import SellCardInputField from "./SellCardInputField";
import SellCardValue from "./SellCardValue";
import SellCardOptions from "./SellCardOptions";
import SellCardMenu from "./SellCardMenu";
import SellCardValueContainer from "./SellCardValueContainer";
import { useSelector } from "react-redux";

const SelectWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  position: relative;
`;

const ErrorMessage = styled.div`
  top: 7px;
  position: absolute;
  font-size: 9px;
  font-weight: 900;
  letter-spacing: 1px;
  left: 26px;
  text-transform: uppercase;
  color: rgb(238, 80, 80);
  z-index: 3;
`;

const SellSelect = ({
  value,
  onChangeFunc,
  onInputKeyDown = f => f,
  merchantError,
  deleteSellCardInCart
}) => {
  const sortedByName = useSelector(state => state.merchantsSell.sortedByName);

  const [selectDisabled, setDisableSelect] = useState(false);

  const filterOption = (merchant, text) =>
    text ? score(merchant.data, text) > -1500 : true;

  return (
    <SelectWrapper className={merchantError ? "select-merchant-error" : ""}>
      {merchantError && <ErrorMessage>Please select a merchant</ErrorMessage>}
      <Select
        filterOption={filterOption}
        placeholder={"Store name..."}
        aria-label="Store name"
        value={value}
        onChange={onChangeFunc}
        onInputKeyDown={onInputKeyDown}
        options={sortedByName}
        components={{
          Input: SellCardInputField,
          ValueContainer: SellCardValue,
          Option: SellCardOptions,
          SingleValue: SellCardValueContainer,
          MenuList: SellCardMenu
        }}
        classNamePrefix="react-select-3"
        blurInputOnSelect={true}
        deleteSellCardInCart={deleteSellCardInCart}
        setDisableSelect={setDisableSelect}
        isDisabled={selectDisabled}
        merchantError={merchantError}
      />
    </SelectWrapper>
  );
};

export default SellSelect;
