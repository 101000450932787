import React, { useCallback } from "react";
import { debounce } from "lodash";
import {
  deleteSellCardInCart,
  updateStepOneFields
} from "@actions/sellActions";
import { defaultAction } from "@actions/defaultAction";
import {
  DISPLAY_NO_BALANCE_ERROR,
  DISPLAY_MIN_BALANCE_ERROR,
  DISPLAY_MAX_BALANCE_ERROR
} from "@constants/actionTypes";
import SellSelect from "./SellSelect";
import styled from "@emotion/styled";
import SellBalance from "./SellBalance";
import { useDispatch, useSelector } from "react-redux";
import CardInSellCartBottom from "./CardInSellCartBottom";

const Main = styled.div`
  width: 95%;
  margin: 0 auto;

  @media (max-width: 710px) {
    width: 90%;
  }
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 190px 121px;
  grid-column-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 710px) {
    grid-template-columns: 1fr 121px;
    grid-gap: 20px;
    margin-top: 13px;
  }
  @media (max-width: 330px) {
    grid-template-columns: 135px 1fr;
  }
`;

const SellSelectContainer = styled.div`
  @media (max-width: 710px) {
    grid-column: 1/3;
  }
`;

const CardNumber = styled.div`
  @media (min-width: 711px) {
    display: none;
  }
  font-size: 12px;
  font-weight: 500;
  color: #1f2021;
`;

const CardInSellCart = ({ cardID, index }) => {
  const dispatch = useDispatch();
  const cards = useSelector(state => state.cards);
  const merchantsSell = useSelector(state => state.merchantsSell);
  const merchantID = useSelector(state => state.cards[cardID].id);
  const enterValue = cards[cardID].enterValue;
  const merchantName = merchantsSell.hasOwnProperty(merchantID)
    ? merchantsSell[merchantID].name
    : "";
  const updateTheBalance = amount => {
    changeEnterVal(amount);
  };
  const debouncePut = useCallback(debounce(updateTheBalance, 500), [
    merchantID
  ]);

  const checkIfBalanceError = currentVal => {
    currentVal = currentVal === "" ? 0 : Number(currentVal);
    if (currentVal === "" || currentVal === 0) {
      dispatch(defaultAction(DISPLAY_NO_BALANCE_ERROR, cardID));
      return false;
    } else if (
      merchantsSell.hasOwnProperty(merchantID) &&
      currentVal > merchantsSell[merchantID].limit.max
    ) {
      dispatch(defaultAction(DISPLAY_MAX_BALANCE_ERROR, cardID));
      return false;
    } else if (
      merchantsSell.hasOwnProperty(merchantID) &&
      currentVal < merchantsSell[merchantID].limit.min
    ) {
      dispatch(defaultAction(DISPLAY_MIN_BALANCE_ERROR, cardID));
      return false;
    } else {
      return true;
    }
  };

  const changeEnterVal = amount => {
    let isValidCard = checkIfBalanceError(amount);
    if (isValidCard) {
      if (amount === "" || amount === ".") {
        return;
      }
      dispatch(updateStepOneFields(cardID, merchantID, amount));
    }
  };

  const preventLettersAndDecimal = evt => {
    var getSelectionStart = function (o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate();
        r.moveEnd("character", o.value.length);
        if (r.text == "") return o.value.length;
        return o.value.lastIndexOf(r.text);
      } else return o.selectionStart;
    };
    const el = document.getElementById(cardID);

    var charCode = evt.which ? evt.which : event.keyCode;
    var number = el.value.split(".");
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    if (number.length > 1 && charCode == 46) {
      evt.preventDefault();
    }
    var caratPos = getSelectionStart(el);
    var dotPos = el.value.indexOf(".");

    if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
      evt.preventDefault();
    }
    return true;
  };

  const changeMerchVal = async val => {
    if (val.length <= 0) {
      return;
    }

    await dispatch(updateStepOneFields(cardID, val.id, enterValue));
  };

  let merchantImage = merchantsSell.hasOwnProperty(merchantID)
    ? merchantsSell[merchantID].image
    : "";
  let minVal = merchantsSell.hasOwnProperty(merchantID)
    ? merchantsSell[merchantID].limit.min
    : "[Unknown]";
  let maxVal = merchantsSell.hasOwnProperty(merchantID)
    ? merchantsSell[merchantID].limit.max
    : "[Unknown]";

  const handleBalanceChange = e => {
    debouncePut(e.target.value);
  };

  const handleEnter = e => {
    preventLettersAndDecimal(e);
  };

  const getErrorMessage = () => {
    return cards[cardID].balanceError === "NO_BALANCE_ERROR"
      ? "Please enter a value!"
      : cards[cardID].balanceError === "MAX_BALANCE_ERROR"
      ? `$${maxVal} is the maximum`
      : cards[cardID].balanceError === "MIN_BALANCE_ERROR"
      ? `$${minVal} is the minimum`
      : "Invalid balance";
  };

  return (
    <Main>
      <CardNumber>Card #{index + 1}</CardNumber>
      <MainGrid className="card-in-sell-cart">
        <SellSelectContainer>
          <SellSelect
            value={{
              value: merchantID,
              label: merchantName,
              image: merchantImage,
              cardID: cardID
            }}
            onChangeFunc={changeMerchVal}
            clearable={false}
            deleteSellCardInCart={cardID =>
              dispatch(deleteSellCardInCart(cardID))
            }
          />
        </SellSelectContainer>
        <SellBalance
          defaultValue={enterValue}
          onChangeFunc={handleBalanceChange}
          handleEnter={handleEnter}
          setId={cardID}
          isThereABalanceError={
            cards[cardID].balanceError.toLowerCase() !== "passed"
          }
          errorMessage={getErrorMessage()}
        />
        <CardInSellCartBottom cardID={cardID} />
      </MainGrid>
    </Main>
  );
};

export default CardInSellCart;
