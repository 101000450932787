import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultAction } from "@actions/defaultAction";
import {
  ADD_CARD_BOX_OPEN,
  KEEP_ADD_CARD_BOX_CLOSED
} from "@constants/actionTypes";

const EachSellCardAnother = () => {
  const dispatch = useDispatch();
  const partnerBrand = useSelector(state => state.config.partnerBrand);

  const handleHideAddCardBox = () => {
    dispatch(defaultAction(ADD_CARD_BOX_OPEN, false));
    dispatch(defaultAction(KEEP_ADD_CARD_BOX_CLOSED, true));
  };
  return (
    <div className="arrow_box">
      <div className="close-btn" onClick={handleHideAddCardBox}>
        <div>x</div>
      </div>
      <div className="text-wrapper">
        <div
          className={
            partnerBrand ? "partner-arrow-box-title" : "arrow-box-title"
          }
        >
          Have another card? Add it here
        </div>
        <div className="light-text-wrapper">
          No need to start another order, simply add it below.
        </div>
      </div>
    </div>
  );
};

export default EachSellCardAnother;
