import React from "react";
import { components } from "react-select";

const valueContainerStyle = {
  paddingLeft: "40px",
  color: "red"
};

const SellCardValue = props => {
  return (
    <components.ValueContainer
      className="select-value-container"
      {...props}
      style={valueContainerStyle}
    />
  );
};

export default SellCardValue;
