import React, { useEffect } from "react";
import { getPaymentOptions } from "@actions/sellActions";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import SellHeaderWithStatus from "@components/SellHeaderWithStatus";
import "../styles/stepOne.scss";
import "../styles/sell.scss";
import { useDispatch, useSelector } from "react-redux";
import { initAppBrowser } from "@actions/initApp";
import StepOneMain from "@components/StepOneMain";
import StepOneSellBottom from "@components/StepOneSellBottom";
import StepOneOfferContainer from "@components/StepOneOfferContainer";

const BottomContainer = styled.div`
  max-width: 1650px;
  margin: 0 auto;
  padding-bottom: 120px;

  @media (max-width: 1730px) {
    max-width: 95%;
  }
  @media (max-width: 710px) {
    max-width: 90%;
  }
`;

const StepOneSell = () => {
  const dispatch = useDispatch();
  const cards = useSelector(state => state.cards);
  const trade = useSelector(state => state.offers.trade);

  useEffect(() => {
    dispatch(initAppBrowser(["messages", "sell"]));
    window.scrollTo(0, 0);
    dispatch(getPaymentOptions());
  }, []);

  let highestPerecentageOff = 0;
  if (trade) {
    trade.forEach(paymentOption => {
      if (highestPerecentageOff < paymentOption.discount) {
        highestPerecentageOff = paymentOption.discount;
      }
    });
  }

  let preventGoToNextStep = false;
  for (var theCard in cards) {
    if (cards[theCard].balanceError !== "PASSED") {
      preventGoToNextStep = true;
    }
  }
  return (
    <div className={`mar-t-128-108 step-one-sell orange-caret`}>
      <Helmet title="Sell Gift Cards for Cash Online - Up to 92% | CardCash" />

      <div>
        <SellHeaderWithStatus />
        <StepOneMain />

        <BottomContainer>
          {!Object.keys(cards).length && (
            <StepOneSellBottom highestPerecentageOff={highestPerecentageOff} />
          )}
          {!!Object.keys(cards).length && !preventGoToNextStep && (
            <StepOneOfferContainer
              highestPerecentageOff={highestPerecentageOff}
            />
          )}
        </BottomContainer>
      </div>
    </div>
  );
};

export default StepOneSell;
