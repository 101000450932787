import React from "react";
import SmallButtonSpinner from "./SmallButtonSpinner";
import { useSelector } from "react-redux";
import CardInSellCartOffer from "./CardInSellCartOffer";

const CardInSellCart = ({ cardID }) => {
  const sellCardOfferLoader = useSelector(
    state => state.cartSell.sellCardOfferLoader
  );
  const cardIds = useSelector(state => state.cartSell.cardIds);

  const isLastInCart = cardID === cardIds[cardIds.length - 1];

  return (
    <>
      {isLastInCart && sellCardOfferLoader ? (
        <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-dark.png" />
      ) : (
        <CardInSellCartOffer cardID={cardID} />
      )}
    </>
  );
};

export default CardInSellCart;
