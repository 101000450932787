import React, { useState } from "react";
import styled from "@emotion/styled";
import { FaDollarSign } from "react-icons/fa";
import { ClearedStyleInput } from "@modules/Components";
import formattedRound from "@utils/formattedRound";

const BalanceWrapper = styled.div`
  position: relative;
  display: flex;
  height: 72px;
  align-items: center;
  border: ${props =>
    props.isThereABalanceError
      ? "1px solid rgba(238, 80, 80, 0.48)"
      : props.balanceFocused
      ? "2px solid black"
      : "0px"};
  border-radius: 5px;
`;

const ErrorMessage = styled.div`
  top: 7px;
  position: absolute;
  font-size: 9px;
  font-weight: 900;
  letter-spacing: 1px;
  left: 26px;
  text-transform: uppercase;
  color: rgb(238, 80, 80);
  z-index: 3;
`;

const PriceTagWrapper = styled.div`
  position: absolute;
`;

const InputField = styled(ClearedStyleInput)`
  color: rgba(31, 32, 33, 0.65);
  height: 100%;
  width: 100%;
  box-shadow: 0 20px 70px 0 rgba(31, 32, 33, 0.12);
  border-radius: 5px;
  background-color: #ffffff;
  padding-left: 49px;
  &:active,
  &:focus,
  &:hover,
  &:focus {
    border: 0;
    box-shadow: 0 20px 70px 0 rgba(31, 32, 33, 0.12);
  }
  &::placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &::-webkit-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }
  &:-ms-input-placeholder {
    color: rgba(31, 32, 33, 0.65);
  }

  @media (max-width: 410px) {
    padding-left: 38px;
  }
`;

const Tag = styled(FaDollarSign)`
  font-size: 17px;
  margin-left: 21px;

  @media (max-width: 410px) {
    margin-left: 13px;
  }
`;

const SellBalance = ({
  isThereABalanceError,
  balanceFocused,
  errorMessage,
  setCheckBalance = f => f,
  balanceRef,
  setBalanceFocus = f => f,
  value,
  handleEnter = f => f,
  onChangeFunc,
  defaultValue,
  setId
}) => {
  const [localFocus, setLocalFocus] = useState(false);
  const [theDefaultValue, changeTheDefaultValue] = useState(
    formattedRound(defaultValue)
  );

  const handleBlur = e => {
    setLocalFocus(false);
    setCheckBalance();
    if (defaultValue) {
      changeTheDefaultValue(formattedRound(e.target.value));
    }
  };

  const handleChange = async e => {
    if (defaultValue) {
      changeTheDefaultValue(e.target.value);
    }
    await onChangeFunc(e);

    await setCheckBalance();
  };

  return (
    <BalanceWrapper
      isThereABalanceError={isThereABalanceError}
      balanceFocused={balanceFocused || localFocus}
      className="sell-balance-wrapper remove-number-input-arrows"
    >
      {isThereABalanceError && (
        <ErrorMessage className="sell-balance-error">
          {errorMessage}
        </ErrorMessage>
      )}
      <PriceTagWrapper>
        <Tag
          style={{
            color: isThereABalanceError
              ? "#ee5050"
              : balanceFocused || localFocus
              ? "rgba(31, 32, 33, 1)"
              : "rgba(31, 32, 33, 0.3)"
          }}
        />
      </PriceTagWrapper>
      <InputField
        ref={balanceRef}
        onBlur={handleBlur}
        onFocus={() => {
          setLocalFocus(true);
          setBalanceFocus(true);
        }}
        value={defaultValue ? theDefaultValue : value}
        onChange={handleChange}
        onKeyPress={handleEnter}
        type="number"
        placeholder="Balance..."
        id={setId}
        className={"sell-balance-input"}
      />
    </BalanceWrapper>
  );
};

export default SellBalance;
