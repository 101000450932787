import React, { useEffect, useState, useRef } from "react";
import { addSellCard, checkBalance } from "@actions/sellActions";
import { defaultAction } from "@actions/defaultAction";
import {
  CARD_PARTIAL_MERCHANT,
  DISPLAY_NO_ERROR_PARTIAL,
  CARD_PARTIAL_BALANCE
} from "@constants/actionTypes";
import styled from "@emotion/styled";
import DefaultCustomButton from "@modules/Components/DefaultCustomButton";
import SellSelect from "./SellSelect";
import SellBalance from "./SellBalance";
import { animateScroll } from "react-scroll";
import SmallButtonSpinner from "./SmallButtonSpinner";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EachSellCardAnother from "./EachSellCardAnother";

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.gridTwo ? "1fr 190px 121px" : "1fr 259px"};
  grid-column-gap: 20px;
  width: 95%;
  margin: 0 auto;

  @media (max-width: 710px) {
    width: 90%;
    grid-template-columns: ${props => (props.gridTwo ? "1fr 121px" : "1fr")};
    grid-gap: 20px;
  }
  @media (max-width: 330px) {
    grid-template-columns: ${props => (props.gridTwo ? "135px 1fr" : "1fr")};
    grid-gap: 20px;
  }
`;

const SellSelectContainer = styled.div`
  @media (max-width: 710px) {
    grid-column: 1/3;
  }
`;

const SellBalanceWrapper = styled.div`
  @media (max-width: 710px) {
    grid-column: ${props => props.gridColumn};
  }
`;

const ButtonContainer = styled.div`
  grid-column: 2/3;

  @media (max-width: 710px) {
    grid-column: 1/3;
  }
`;

const Divider = styled.div`
  grid-column: 1/3;
  height: 1px;
  background-color: rgba(119, 121, 132, 0.15);
  margin-top: 30px;
  margin-bottom: 30px;
`;

const CardNumber = styled.div`
  @media (min-width: 711px) {
    display: none;
  }

  width: 95%;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 500;
  color: #1f2021;
  margin-bottom: 13px;

  @media (max-width: 710px) {
    width: 90%;
  }
`;

const EachSellCard = ({ mainContentRef }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const merchantsSell = useSelector(state => state.merchantsSell);
  const cardPartial = useSelector(state => state.cardPartial);
  const cards = useSelector(state => state.cards);
  const displayAddCardBox = useSelector(
    state => state.userActions.displayAddCardBox
  );
  const sellCardOfferLoader = useSelector(
    state => state.cartSell.sellCardOfferLoader
  );

  const [balanceFocused, setBalanceFocus] = useState(false);
  const [buttonDisplayValue, setButtonDisplayValue] = useState(
    Object.keys(cards).length === 0 ? "Get offer" : "Add"
  );

  const balanceRef = useRef(null);
  const isThereABalanceError =
    cardPartial.displayBalanceError ||
    cardPartial.displayCardBalanceFormatError ||
    cardPartial.displayMaxError ||
    cardPartial.displayMinError;

  const merchantError = cardPartial.displayMerchantError;

  const invalid =
    !cardPartial.selectedMerchant ||
    !cardPartial.cardBalance ||
    isThereABalanceError ||
    merchantError;

  useEffect(() => {
    if (Object.keys(cards).length === 0) {
      setButtonDisplayValue("Get offer");
    }
  }, [cards]);

  useEffect(() => {
    if (location.state && location.state.merchID) {
      if (merchantsSell[location.state.merchID]) {
        setPreselectedMerchant(location.state.merchID);
        history.replace({
          pathname: `${location.pathname}${location.search}`,
          state: {}
        });
      }
    }
  }, [location.state]);

  const setPreselectedMerchant = merchID => {
    const preSelectedMerchant = merchantsSell[merchID];
    if (preSelectedMerchant) {
      submitCardPartialMerchant(preSelectedMerchant);
    }
  };

  const setAddSellCard = () => dispatch(addSellCard());

  const setCheckBalance = () => {
    setBalanceFocus(false);
    dispatch(checkBalance());
  };

  const handleEnter = evt => {
    if (evt.which === 13 || evt.keyCode === 13) {
      setAddSellCard();
    }
  };

  const submitCardPartialMerchant = merch => {
    const isEmptyArray = arr => Array.isArray(arr) && !arr.length;
    if (isEmptyArray(merch)) {
      dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL));
      dispatch(defaultAction(CARD_PARTIAL_MERCHANT, ""));
    } else {
      dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL));
      dispatch(defaultAction(CARD_PARTIAL_MERCHANT, merch));

      if (!cardPartial.cardBalance) {
        balanceRef.current.focus();
      }
    }
  };

  let maxVal = "";
  let minVal = "";

  try {
    minVal = cardPartial.selectedMerchant.limit.min;
    maxVal = cardPartial.selectedMerchant.limit.max;
  } catch (err) {}

  const getErrorMessage = () => {
    return cardPartial.displayBalanceError
      ? "Please enter a card value!"
      : cardPartial.displayMaxError
      ? `$${maxVal} is the maximum`
      : cardPartial.displayMinError
      ? `$${minVal} is the minimum`
      : cardPartial.displayCardBalanceFormatError
      ? "Invalid balance"
      : "";
  };

  const buttonClicked = () => {
    setBalanceFocus(false);
    if (!invalid) {
      setButtonDisplayValue(
        <SmallButtonSpinner img="https://cdn.cardcash.com/website/ui/elements/spinner-white.png" />
      );
      if (Object.keys(cards).length === 0) {
        animateScroll.scrollTo(mainContentRef.current.offsetTop, {
          smooth: "linear",
          duration: 500,
          delay: 1000
        });
      }
    }

    setAddSellCard();
  };

  return (
    <div className="position-relative">
      {Object.keys(cards).length &&
      Object.keys(cards).length < 2 &&
      displayAddCardBox &&
      !sellCardOfferLoader ? (
        <EachSellCardAnother />
      ) : null}
      <div>
        <div>
          {Object.keys(cards).length > 0 && (
            <CardNumber>Card #{Object.keys(cards).length + 1}</CardNumber>
          )}
          {!sellCardOfferLoader && (
            <MainGrid gridTwo={Object.keys(cards).length < 1 ? false : true}>
              <SellSelectContainer>
                <SellSelect
                  value={cardPartial.selectedMerchant}
                  onChangeFunc={submitCardPartialMerchant}
                  onInputKeyDown={handleEnter}
                  merchantError={merchantError}
                />
              </SellSelectContainer>
              <SellBalanceWrapper
                gridColumn={Object.keys(cards).length < 1 ? "1/3" : "1/2"}
              >
                <SellBalance
                  isThereABalanceError={isThereABalanceError}
                  balanceFocused={balanceFocused}
                  errorMessage={getErrorMessage()}
                  balanceRef={balanceRef}
                  setCheckBalance={setCheckBalance}
                  setBalanceFocus={setBalanceFocus}
                  value={cardPartial.cardBalance}
                  onChangeFunc={e =>
                    dispatch(
                      defaultAction(CARD_PARTIAL_BALANCE, e.target.value)
                    )
                  }
                  handleEnter={handleEnter}
                />
              </SellBalanceWrapper>
              {Object.keys(cards).length > 0 && (
                <DefaultCustomButton
                  handleClick={setAddSellCard}
                  disabled={invalid}
                  classes={"main-default-orange-button"}
                  type="button"
                  buttonType={"WHITE_BUTTON_GREEN_COLOR"}
                  name="submit"
                >
                  <span>Add</span>
                </DefaultCustomButton>
              )}
            </MainGrid>
          )}
          {Object.keys(cards).length === 0 && (
            <>
              <Divider />
              <MainGrid>
                <ButtonContainer>
                  <DefaultCustomButton
                    handleClick={buttonClicked}
                    disabled={invalid}
                    classes={"main-default-orange-button"}
                    type="button"
                    buttonType={"GREEN_BUTTON"}
                  >
                    <span>{buttonDisplayValue}</span>
                  </DefaultCustomButton>
                </ButtonContainer>
              </MainGrid>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EachSellCard;
