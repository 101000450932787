import React from "react";
import { currencyFormatter } from "@utils";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const OfferDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const OfferTextEmoji = styled.div`
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1px;
`;

const OfferText = styled.span`
  color: rgba(31, 32, 33, 0.5);
  text-transform: uppercase;
`;

const OfferAmount = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #1f2021;
`;

const InvalidSpan = styled.span`
  color: rgb(238, 80, 80);
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
  text-align: center;
`;

const DollarSpan = styled.span`
  color: rgba(31, 32, 33, 0.5);
`;

const CardInSellCartOffer = ({ cardID }) => {
  const cards = useSelector(state => state.cards);
  const cashValue = cards[cardID].cashValue;

  return (
    <OfferDiv>
      {cards[cardID].balanceError === "MIN_BALANCE_ERROR" ||
      cards[cardID].balanceError === "MAX_BALANCE_ERROR" ||
      cards[cardID].balanceError === "NO_BALANCE_ERROR" ? (
        <InvalidSpan>
          Invalid
          <br /> Balance
        </InvalidSpan>
      ) : (
        <div>
          <OfferTextEmoji>
            💰 <OfferText>YOU GET</OfferText>
          </OfferTextEmoji>
          <OfferAmount>
            <DollarSpan>$</DollarSpan>
            {currencyFormatter(cashValue, "no-symbol")}
          </OfferAmount>
        </div>
      )}
    </OfferDiv>
  );
};

export default CardInSellCartOffer;
